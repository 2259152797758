import {
  MinusCircleOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form, Button, Input } from "antd";

export const AddContactPersonDynamicFields = () => {
  return (
    <Form.List name="contactPersons">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field) => (
              <div className="contact-person" key={field.key}>
                <div className="contact-person__item">
                  <Form.Item
                    name={[field.name, "name"]}
                    label="Контактна особа"
                    // initialValue={"test"}
                  >
                    <Input
                      placeholder="Контактна особа"
                      prefix={<UserOutlined />}
                    />
                  </Form.Item>
                </div>

                <div className="contact-person__item">
                  <Form.Item
                    name={[field.name, "phone"]}
                    label="Номер телефону"
                  >
                    <Input
                      placeholder="+38 (---) -------"
                      prefix={<PhoneOutlined />}
                    />
                  </Form.Item>
                </div>
                <MinusCircleOutlined
                  style={{
                    position: "absolute",
                    right: "-25px",
                    bottom: "21px",
                  }}
                  onClick={() => remove(field.name)}
                />
              </div>
            ))}

            <Button className="btn-add-contact-person" onClick={() => add()}>
              <PlusCircleOutlined />
              Додати номер телефону
            </Button>
          </>
        );
      }}
    </Form.List>
  );
};
