import { BellOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import { AntDesignIcon } from "components/AntDesignIcon/AntDesignIcon";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

export const AdminHeader = ({ onClickLogOut }) => {
  const navigate = useNavigate();
  const onClickNotification = () => {
    console.log("onClickNotification");
  };

  return (
    <Layout>
      <Header className="header">
        <div className="headerLogo" onClick={() => navigate("/")}>
          <AntDesignIcon width={"24px"} marginRight={"9px"} />
          Моя громада
        </div>
        <div onClick={onClickNotification} className="notification">
          <BellOutlined style={{ fontSize: "16px" }} />
          <div className="count-notification">9</div>
        </div>
        <div onClick={onClickLogOut} className="btn-logout">
          <img
            alt="Вийти"
            src="/assets/img/log-out.svg"
            style={{
              width: "14px",
            }}
          />
          Вийти
        </div>
      </Header>
    </Layout>
  );
};
