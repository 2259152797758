import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { useSelector } from "react-redux";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const UploadPhoto = ({ error, onChangeData }) => {
  const { activePlace } = useSelector((state) => state.community);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (activePlace?.images?.length > 0) {
      const list = activePlace?.images.map((img, ind) => ({
        uid: ind + 1,
        status: "done",
        url: img,
        name: img,
      }));

      setFileList(list);
      onChangeData(list);
    }
  }, []);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    onChangeData(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Додати фото
      </div>
    </div>
  );
  const uploadDraggButton = (
    <div style={{ padding: "16px" }}>
      <img src="/assets/img/box.svg" alt="box" />
      <p>
        <strong>Натиснить сюди, щоб завантажити фото</strong>
      </p>
      <p style={{ margin: 0 }}>Або перетягніть на це поле фото з комп’ютера</p>
    </div>
  );

  return (
    <div
      className={
        fileList.length === 0
          ? `custom-upload-box no-data ${error ? "error" : ""}`
          : "custom-upload-box"
      }
    >
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={(images) => handleChange(images)}
        accept=".jpg,.jpeg"
        multiple
        beforeUpload={(_) => false}
      >
        {fileList.length >= 15
          ? null
          : fileList.length === 0
          ? uploadDraggButton
          : uploadButton}
      </Upload>
      <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};
