import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { store } from "./redux/store";
import { ConfigProvider } from "antd";
import { App } from "./App";
import "./index.css";
import { App as AppAntd } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: { fontFamily: "Roboto, sans-serif" },
      }}
    >
      <AppAntd>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppAntd>
    </ConfigProvider>
  </Provider>
);
