export const handleHowToUseList = (state, { payload }) => {
	state.howToUseList = payload;
};

export const handleHowToUseItem = (state, { payload }) => {
	state.howToUseItem = payload;
};

export const handleSuggestions = (state, { payload }) => {
	state.suggestions = payload;
};

export const handleSuggestion = (state, { payload }) => {
	state.suggestion = payload;
} ;
