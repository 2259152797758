import Icon from "@ant-design/icons";

export const AntDesignIcon = ({ width, marginRight }) => {
  const AntSvg = () => (
    <svg fill="none" viewBox="0 0 47 47">
      <g clipPath="url(#a)">
        <path
          fill="url(#b)"
          d="M21.309 1.46 1.138 21.549a2.75 2.75 0 0 0 0 3.904l20.17 20.087a2.777 2.777 0 0 0 3.921 0l8.458-8.422a2.47 2.47 0 0 0 0-3.502 2.494 2.494 0 0 0-3.517 0L23.758 40a.66.66 0 0 1-.95 0L6.702 23.96a.653.653 0 0 1 0-.946L22.809 6.972a.66.66 0 0 1 .95 0l6.41 6.385a2.494 2.494 0 0 0 3.518 0 2.47 2.47 0 0 0 0-3.503L25.23 1.433a2.804 2.804 0 0 0-3.921.028Z"
        />
        <path
          fill="url(#c)"
          d="M21.309 1.46 1.138 21.549a2.75 2.75 0 0 0 0 3.904l20.17 20.087a2.777 2.777 0 0 0 3.921 0l8.458-8.422a2.47 2.47 0 0 0 0-3.502 2.494 2.494 0 0 0-3.517 0L23.758 40a.66.66 0 0 1-.95 0L6.702 23.96a.653.653 0 0 1 0-.946L22.809 6.972c.672-.578 1.768-1.747 3.379-2.021 1.196-.203 2.505.244 3.925 1.342l-4.883-4.86a2.804 2.804 0 0 0-3.921.028Z"
        />
        <path
          fill="url(#d)"
          d="M35.639 31.747a2.494 2.494 0 0 0 3.517 0l6.239-6.213a2.75 2.75 0 0 0 0-3.904l-6.293-6.245a2.499 2.499 0 0 0-3.52.003 2.47 2.47 0 0 0 0 3.503l4.25 4.232c.27.269.27.677 0 .946l-4.193 4.175a2.47 2.47 0 0 0 0 3.503Z"
        />
        <path
          fill="url(#e)"
          d="M23.37 29.024c3.008 0 5.446-2.428 5.446-5.424 0-2.995-2.438-5.423-5.446-5.423-3.008 0-5.446 2.428-5.446 5.423 0 2.996 2.438 5.424 5.446 5.424Z"
        />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1="21.495"
          x2="40.629"
          y1=".647"
          y2="12.371"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4285EB" />
          <stop offset="1" stopColor="#2EC7FF" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="24.067"
          x2="14.66"
          y1=".647"
          y2="49.426"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#29CDFF" />
          <stop offset=".379" stopColor="#148EFF" />
          <stop offset="1" stopColor="#0A60FF" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="42.766"
          x2="30.506"
          y1="12.351"
          y2="31.589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA816E" />
          <stop offset=".415" stopColor="#F74A5C" />
          <stop offset="1" stopColor="#F51D2C" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="25.344"
          x2="21.271"
          y1="14.305"
          y2="30.653"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA8E7D" />
          <stop offset=".513" stopColor="#F74A5C" />
          <stop offset="1" stopColor="#F51D2C" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M.173.5h46.154v46H.173z" />
        </clipPath>
      </defs>
    </svg>
  );
  const CustomIcon = (props) => <Icon component={AntSvg} {...props} />;
  return (
    <>
      <CustomIcon
        style={{ width: `${width}`, marginRight: `${marginRight}` }}
      />
    </>
  );
};
