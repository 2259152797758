import { Breadcrumb } from "antd";
import { Content } from "antd/es/layout/layout";
import { Link } from "react-router-dom";

export const Settings = () => {
  return (
    <Content className="content">
      <div className="content-header">
        <Breadcrumb
          className="breadcrumb"
          items={[
            {
              title: <Link to="/">Головна</Link>,
            },
            {
              title: "Налаштування",
            },
          ]}
        />
        <div className="content-title">
          <ul className="content-title__list">
            <li className="content-title__item">Налаштування</li>
          </ul>
        </div>
      </div>
      <div className="content-conteiner">
        <div>Settings admin panel ...</div>
      </div>
    </Content>
  );
};
