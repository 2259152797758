import { Button, Result } from "antd";
import { useNavigate } from "react-router";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        margin: " 0 auto",
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle={
          "Сторінку не знайдено, не створено або Ви не авторизовані. Для входу пройдіть авторизацію"
        }
        extra={
          <Button onClick={() => navigate("/")} type="primary">
            Авторизація
          </Button>
        }
      />
    </div>
  );
};
