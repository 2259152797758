import {
  AutoComplete,
  App,
  Avatar,
  Breadcrumb,
  Button,
  Input,
  Modal,
  Switch,
  Tabs,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAdmin,
  getAdministrators,
  getPlaces,
  getTowns,
  updateStatusCommunity,
} from "redux/community/communityOperations";
import { AdminsTable } from "components/AdminsTable/AdminsTable";
import { TownsList } from "components/TownsList/TownsList";
import { Places } from "components/Places/Places";
import { onScrollUp } from "utils/onScrollUp";

export const Towns = () => {
  const { message } = App.useApp();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const { currentState, currentDistrict, currentCommunity, usersNoAdmins } =
    useSelector((state) => state.community);

  const [switchActiveCommunity, setSwitchActiveCommunity] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputData, setInpuData] = useState("");
  const [prefixUrl, setPrefixUrl] = useState("/assets/img/avatar.png");
  const communitytId = Number(params.id);

  useEffect(() => {
    setSwitchActiveCommunity(currentCommunity.isAvailable);
  }, [currentCommunity]);

  useEffect(() => {
    onScrollUp();
    dispatch(getAdministrators(communitytId));
    dispatch(getTowns(communitytId));
    dispatch(getPlaces(communitytId));
  }, []);

  const messageSucces = () => {
    message.success("Адміністратор успішно доданий");
  };
  const messageError = () => {
    message.error("Виникла помилка при збереженні даних");
  };

  const onChangeInput = (v) => {
    setInpuData(v);

    for (let i = 0; i < usersNoAdmins.length; i++) {
      if (v === usersNoAdmins[i]?.email) {
        setPrefixUrl(usersNoAdmins[i]?.avatar || "/assets/img/avatar.png");
        break;
      } else {
        setPrefixUrl("/assets/img/avatar.png");
      }
    }
  };

  const onChangeActiveCommunity = (e) => {
    setSwitchActiveCommunity(e);
    dispatch(updateStatusCommunity(currentCommunity.uuid, e));
  };

  const showModalAddAdmin = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      addNewAdmin(currentCommunity, inputData, messageSucces, messageError)
    );

    setIsModalOpen(false);
    setInpuData("");
    setPrefixUrl("/assets/img/avatar.png");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setInpuData("");
    setPrefixUrl("/assets/img/avatar.png");
  };

  const onChangeTabs = (key) => {
    if (Number(key) === 1) {
    } else {
      document.querySelector(".add-admin-btn")?.classList.remove("no-active");
      document.querySelector(".add-admin-btn")?.classList.add("no-active");
    }
  };
  const itemsTab = [
    {
      key: "towns",
      label: "Населені пункти",
      children: <TownsList />,
    },
    {
      key: "places",
      label: "Місця",
      children: <Places />,
    },
    {
      key: "admins",
      label: "Адміністратори",
      children: <AdminsTable showModalAddAdmin={showModalAddAdmin} />,
    },
  ];

  const prefix = <Avatar src={prefixUrl} />;

  const options = usersNoAdmins?.map((user) => {
    return {
      key: user.key,
      value: user?.email || "no email",
      img: user?.avatar,
      label: (
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "12px" }}>
            <Avatar size={45} src={user?.avatar || "/assets/img/avatar.png"} />
          </div>

          <div>
            <div style={{ fontSize: "16px", fontWeight: "500" }}>
              {user?.firstName || "-"} {user?.lastName || ""}{" "}
              {user?.thirdName || ""}
            </div>
            <div style={{ color: "#8C8C8C" }}> {user?.email || "no email"}</div>
          </div>
        </div>
      ),
    };
  });

  return (
    <Content className="content">
      <div className="content-header">
        <Breadcrumb
          className="breadcrumb"
          items={[
            {
              title: <Link to="/">Області</Link>,
            },
            {
              title: (
                <Link to={`/state/${currentState.id}`}>
                  {currentState.name}
                </Link>
              ),
            },
            {
              title: (
                <Link to={`/state/${currentState.id}/${currentDistrict.id}`}>
                  {currentDistrict.name}
                </Link>
              ),
            },
            {
              title: currentCommunity.name,
            },
          ]}
        />
        <div className="content-title">
          <ul className="content-title__list">
            <li className="content-title__item">{currentCommunity.name}</li>
          </ul>

          <Button
            onClick={showModalAddAdmin}
            className="add-admin-btn no-active"
          >
            <PlusOutlined style={{ fontSize: "18px" }} />
            Додати адміністратора
          </Button>

          <Switch
            checkedChildren="Вкл"
            unCheckedChildren="Викл"
            checked={switchActiveCommunity}
            onClick={onChangeActiveCommunity}
          />
        </div>
      </div>
      <Tabs
        className="towns-tabs"
        defaultActiveKey={location?.state?.activeTab || "towns"}
        items={itemsTab}
        onChange={onChangeTabs}
      />
      <Modal
        bodyStyle={{ minHeight: "70px", marginTop: "20px" }}
        title="Додати адміністратора"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="not" onClick={handleCancel}>
            Відмінити
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Зберегти
          </Button>,
        ]}
      >
        <AutoComplete
          // open={false}
          value={inputData}
          onChange={onChangeInput}
          allowClear={true}
          className="auto-complete-add-admin"
          options={options}
          filterOption={(inputValue, option) =>
            option.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !==
            -1
          }
        >
          <Input
            prefix={prefix}
            size="large"
            placeholder="Пошук по email"
            suffix={<SearchOutlined />}
          />
        </AutoComplete>
      </Modal>
    </Content>
  );
};
