import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";

import { authSlice } from "./auth/authReducer";
import { communitySlice } from "./community/communityReducer";
import {rootReducer} from "./root/rootSlice";

const reducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [communitySlice.name]: communitySlice.reducer,
  root: rootReducer,
});

const middleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer,
  middleware,
});
