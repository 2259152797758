import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Image, Input, Table } from "antd";
import { TYPES } from "components/EditPlace/EditFormCityCouncilSettings/FormGeneralInfo";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getActivePlace,
  getAdministratorsActivePlace,
} from "redux/community/communityOperations";

export const Places = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { places } = useSelector((state) => state.community);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!places) return setFilteredData([]);

    setFilteredData(places);
  }, [places]);

  const onClickEditPlace = (place) => {
    dispatch(getActivePlace(place));
    dispatch(getAdministratorsActivePlace(place.placeId));

    navigate("edit-place");
  };

  const onSearchInput = (value, _e, info) => {
    const searchText = value.target.value.toLowerCase().trim();

    const newData = places.filter((item) => {
      return item.name.toLowerCase().includes(searchText);
    });

    setFilteredData(newData);
  };

  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: "#00000040",
      }}
    />
  );

  const columns = [
    {
      title: (
        <span style={{ display: "block", textAlign: "center" }}>Лого</span>
      ),
      width: 70,
      className: "categ-details-table__photo",
      render: (item) => {
        return (
          <Image
            width={50}
            src={item.icon || "/assets/img/no.png"}
            preview={false}
          />
        );
      },
    },
    {
      title: (
        <span style={{ display: "block", textAlign: "center" }}>Фото</span>
      ),
      width: 70,
      className: "categ-details-table__photo",
      render: (item) => {
        if (item?.images?.length > 0) {
          return (
            <Image.PreviewGroup items={[...item.images]}>
              <Image width={50} height={50} src={item.images[0]} />
            </Image.PreviewGroup>
          );
        } else {
          return <Image width={50} src={"/assets/img/no.png"} />;
        }
      },
    },
    {
      title: (
        <span style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          Назва{" "}
          <Input
            allowClear
            suffix={suffix}
            size="large"
            placeholder="Пошук по назві"
            onChange={onSearchInput}
            style={{ borderRadius: "0", border: "none" }}
          />
        </span>
      ),
      render: (item) => item.name,
    },
    {
      title: "Населеный пунк",
      render: (item) => item.formattedAddress,
    },
    {
      title: "Тип",
      width: 110,
      render: (item) =>
        item?.placeType
          ? TYPES.filter((el) => el.type === item?.placeType)[0].name
          : "- -",
    },
    {
      title: <span style={{ display: "block", textAlign: "center" }}>Дії</span>,
      width: 120,
      render: (_, item) => (
        <div className={"info-category-box-action"}>
          <EditOutlined
            style={{ fontSize: "18px", cursor: "pointer" }}
            onClick={() => onClickEditPlace(item)}
          />
          <EyeOutlined
            style={{ fontSize: "18px", cursor: "pointer" }}
            // onClick={() => onClickEdit(item)}
          />
          <DeleteOutlined
            style={{
              color: "#D72653",
              fontSize: "18px",
              cursor: "pointer",
            }}
            // onClick={() => onClickDeleteCategory(item)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="content-conteiner">
      <Table
        className="towns-admin-table no-active"
        pagination={false}
        dataSource={filteredData}
        columns={columns}
        scroll={{
          y: "calc(100vh - 340px)",
        }}
      />
    </div>
  );
};
