import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CopyOutlined, GlobalOutlined, MailOutlined } from "@ant-design/icons";
import {
  Anchor,
  App,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Switch,
  TimePicker,
} from "antd";
import { LocationComponent } from "components/LocationComponent/LocationComponent ";
import { UploadPhoto } from "./UploadPhoto";
import { useDispatch, useSelector } from "react-redux";
import { AddContactPersonDynamicFields } from "./AddContactPersonDynamicFields";
import {
  messengersList,
  socialNetworksList,
  workTimeData,
  workTimeValidate,
  currentWorkDays,
  currentWorkTimes,
  completeAddress,
} from "components/EditPlace/EditFormCityCouncilSettings/addFormsOperations";
import TextArea from "antd/es/input/TextArea";
import { saveEditCityCouncilSettings } from "redux/community/communityOperations";
import { generateBusinessCollection } from "redux/community/main/thunks";

export const TYPES = [
  { name: "Школа", type: "school" },
  { name: "Мерія", type: "city_hall" },
  { name: "Лікарня", type: "hospital" },
  { name: "Бізнес", type: "business" },
];

export const FormGeneralInfo = () => {
  const location = useLocation();
  const prevUrl = location.pathname.slice(0, 18);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activePlace } = useSelector((state) => state.community);
  console.log("activePlace:", activePlace);

  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);

  const openingHours = activePlace?.openingHours
    ? activePlace.openingHours.weekdayText
    : null;

  const addressFromDB = activePlace?.addressComponents;

  const [workDays, setWorkDays] = useState(currentWorkDays(openingHours));
  const [workTimes, setWorkTimes] = useState(currentWorkTimes(openingHours));

  const [images, setImages] = useState([]);
  const [newLocation, setNewLocation] = useState();

  const [requiredErrPhoto, setRequiredErrPhoto] = useState(false);

  const requiredFirldsError = () => {
    if (images.length === 0) {
      setRequiredErrPhoto(true);
      message.error("Будь-ласка, додайте хоча б одну фотографію установи", 7);
    }

    message.error('Поле "Назва" є обов\'язковим', 5);

    document.getElementById("part-1").scrollIntoView({
      block: "center",
      behavior: "smooth",
    });

    return "Обов'язкове поле";
  };

  const messageSucces = () => {
    message.success("Дані успішно збережені");
  };
  const messageError = () => {
    message.error("Виникла помилка при збереженні даних");
  };

  const onFinish = (values) => {
    if (images.length === 0) {
      setRequiredErrPhoto(true);
      document.getElementById("part-1").scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      message.error("Будь-ласка, додайте хоча б одну фотографію установи", 7);
      return;
    }

    setRequiredErrPhoto(false);

    if (!workTimeValidate(workDays, workTimes))
      return message.error(
        "Будь-ласка, вкажіть робочі години у вибраних днях!",
        7
      );

    setLoading(true);

    const newDataAddressFromInput = {
      home: values.adress_number_home,
      street: values.adress_street,
      town: values.adress_town,
      apartment: values.adress_number_apartment,
      entrance: values.adress_number_entrance,
      additionalInfo: values.adress_additional_information,
    };

    const data = {
      id: activePlace.placeId,

      addressComponents: completeAddress(
        addressFromDB,
        newDataAddressFromInput
      ),

      openingHours: {
        weekdayText: workTimeData(workDays, workTimes),
      },

      phones: values.contactPersons,

      name: values?.title || null,
      email: values?.email || null,
      website: values?.website || null,
      socialNetworks: socialNetworksList(
        values.facebook?.trim() || null,
        values.instagram?.trim() || null
      ),
      messengers: messengersList(
        values.telegram?.trim() || null,
        values.viber?.trim() || null
      ),
      index: Date.now(),
      placeType: values?.placeType || null,
    };

    if (newLocation) {
      data.geometry = {
        location: {
          lat: newLocation.lat,
          lng: newLocation.lng,
        },
      };
    }
    if (values.isActiveSwitch !== undefined) {
      data.available = values.isActiveSwitch;
    }

    // console.log("onFinish  values:", values);
    // console.log("onFinish  data:", data);

    dispatch(
      saveEditCityCouncilSettings(data, images, messageSucces, messageError)
    );
    dispatch(generateBusinessCollection(data.id));

    setLoading(false);

    navigate(prevUrl, { state: { activeTab: "places" } });
  };

  const onChangeCheckbox = (value) => {
    setWorkDays({
      ...workDays,
      [value]: !workDays[value],
    });
  };
  const onChangeTimePicker = (value, day) => {
    setWorkTimes({
      ...workTimes,
      [day]: value,
    });
  };

  const onClickBtnCopyWorkTime = (day) => {
    const keysDays = Object.keys(workDays);
    let updWorkDays = {};

    for (const key of keysDays) {
      updWorkDays[key] = true;
    }
    setWorkDays(updWorkDays);

    const keysTimes = Object.keys(workTimes);
    let updWorkTimes = {};
    const currentTime = workTimes[day];

    for (const key of keysTimes) {
      updWorkTimes[key] = currentTime;
    }
    setWorkTimes(updWorkTimes);
  };

  return (
    <div className="content-conteiner form-general-info">
      <div id="part-1" className="form-title-box">
        <h2>Загальна інформація</h2>
      </div>

      <Col className="general-form-box">
        <div className="title-photo-box" style={{ fontSize: "16px" }}>
          Фотографії <span>(формат .jpg, .jpeg)</span>
        </div>

        <UploadPhoto
          error={requiredErrPhoto}
          onChangeData={(e) => setImages(e)}
        />
      </Col>
      <Form
        // form={form}
        id="title"
        className="general-info-form"
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        initialValues={{
          contactPersons:
            !activePlace.phones || activePlace.phones.length === 0
              ? [{}]
              : activePlace.phones,
          emailList: [],
        }}
      >
        <Form.Item
          name="title"
          label="Назва"
          initialValue={activePlace?.name}
          rules={[
            {
              required: true,
              message: () => requiredFirldsError(),
            },
          ]}
        >
          <TextArea placeholder="Назва" autoSize={true} />
        </Form.Item>
        <Form.Item
          labelAlign="right"
          name="isActiveSwitch"
          valuePropName="checked"
          label="Статус"
          className="form-item-to-row"
        >
          <Switch defaultChecked={activePlace.available} checked />
        </Form.Item>

        <Form.Item
          name="placeType"
          label="Тип"
          initialValue={activePlace?.placeType}
        >
          <Select allowClear id="type" placeholder="Виберіть тип">
            {TYPES?.map((item, ind) => (
              <Select.Option key={ind} value={item?.type}>
                <div className="category-select-option">{item?.name}</div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Divider className={"divider-form"} />

        <div id="part-2" className="form-title-box">
          <h2>Контактна інформація</h2>
        </div>

        <div className="add-contact-person-box">
          <AddContactPersonDynamicFields />
        </div>

        <Form.Item
          style={{ marginBottom: "8px" }}
          name="email"
          label="Email"
          initialValue={activePlace?.email}
        >
          <Input placeholder="Електронна адреса" prefix={<MailOutlined />} />
        </Form.Item>

        <div className="add-contact-person-box">
          {/* <AddContactEmailDynamicFields /> */}
        </div>

        <Form.Item
          name="website"
          label="Веб-сайт"
          initialValue={activePlace?.website}
        >
          <Input placeholder="Веб-сайт" prefix={<GlobalOutlined />} />
        </Form.Item>

        <Form.Item
          name="facebook"
          label="Facebook"
          initialValue={activePlace?.socialNetworks?.facebook}
        >
          <Input
            placeholder="Посилання"
            prefix={
              <img width={16} src="/assets/img/Facebook.png" alt="Facebook" />
            }
          />
        </Form.Item>
        <Form.Item
          name="instagram"
          label="Instagram"
          initialValue={activePlace?.socialNetworks?.instagram}
        >
          <Input
            placeholder="Посилання"
            prefix={
              <img width={16} src="/assets/img/Instagram.png" alt="Instagram" />
            }
          />
        </Form.Item>
        <Form.Item
          name="telegram"
          label="Telegram"
          initialValue={activePlace?.messengers?.telegram}
        >
          <Input
            placeholder="Посилання"
            prefix={
              <img width={16} src="/assets/img/Telegram.png" alt="Telegram" />
            }
          />
        </Form.Item>
        <Form.Item
          name="viber"
          label="Viber"
          initialValue={activePlace?.messengers?.viber}
        >
          <Input
            placeholder="Посилання"
            prefix={<img width={16} src="/assets/img/Viber.png" alt="Viber" />}
          />
        </Form.Item>
        {/* </Col> */}
        <Divider className={"divider-form"} />
        <div id="part-4" className="form-title-box">
          <h2>Розташування</h2>
        </div>
        <Form.Item
          name="adress_town"
          label="Населений пункт"
          initialValue={addressFromDB[2]?.shortName}
        >
          <Input placeholder="Населений пункт" />
        </Form.Item>
        <Form.Item
          name="adress_street"
          label="Вулиця"
          initialValue={addressFromDB[1]?.shortName}
        >
          <Input placeholder="Вулиця" />
        </Form.Item>
        <div className="adress-home-block">
          <Form.Item
            name="adress_number_home"
            label="Будинок"
            initialValue={addressFromDB[0]?.shortName}
          >
            <Input placeholder="№" />
          </Form.Item>
          <Form.Item
            name="adress_number_entrance"
            label="Під‘їзд"
            initialValue={addressFromDB[7]?.entrance}
          >
            <Input placeholder="-" />
          </Form.Item>
          <Form.Item
            name="adress_number_apartment"
            label="Квартира"
            initialValue={addressFromDB[7]?.apartment}
          >
            <Input placeholder="-" />
          </Form.Item>
        </div>
        <Form.Item
          name="adress_additional_information"
          label="Додаткова інформація"
          initialValue={addressFromDB[7]?.additionalInfo}
        >
          <TextArea
            placeholder="Додаткова інформація"
            autoSize={(true, { minRows: 6 })}
          />
        </Form.Item>
        {/********* Карта **********/}
        <div className="general-form-map">
          <LocationComponent onChangeData={(loc) => setNewLocation(loc)} />
        </div>
        <Divider className={"divider-form"} />
        <div id="part-5" className="form-title-box">
          <h2>Розклад роботи</h2>
        </div>
        <Col style={{ width: "621px", margin: "0 auto" }}>
          {/*** Mon ***/}
          <Space direction="horizontal" className="space-to-row">
            <Form.Item>
              <Checkbox
                className="item-work-days__checkbox"
                checked={workDays.workDayMon}
                onChange={() => onChangeCheckbox("workDayMon")}
              >
                Понеділок
              </Checkbox>
            </Form.Item>
            <Form.Item className="item-work-days" label="Робочі години">
              <TimePicker.RangePicker
                // defaultValue={"15:00"}
                value={workTimes.workTimeMon}
                disabled={!workDays.workDayMon}
                format="HH:mm"
                minuteStep="5"
                placeholder={["Початок", "Кінець"]}
                onChange={(e) => onChangeTimePicker(e, "workTimeMon")}
              />
            </Form.Item>
            <Button
              onClick={() => onClickBtnCopyWorkTime("workTimeMon")}
              className="btn-copy-work-time"
              disabled={!workDays.workDayMon}
            >
              <CopyOutlined />
              Продублювати на всі дні
            </Button>
          </Space>
          {/*** Tue ***/}
          <Space direction="horizontal" className="space-to-row">
            <Form.Item>
              <Checkbox
                checked={workDays.workDayTue}
                onChange={() => onChangeCheckbox("workDayTue")}
                className="item-work-days__checkbox"
              >
                Вівторок
              </Checkbox>
            </Form.Item>
            <Form.Item className="item-work-days" label="Робочі години">
              <TimePicker.RangePicker
                value={workTimes.workTimeTue}
                disabled={!workDays.workDayTue}
                format="HH:mm"
                minuteStep="5"
                placeholder={["Початок", "Кінець"]}
                onChange={(e) => onChangeTimePicker(e, "workTimeTue")}
              />
            </Form.Item>
            <Button
              onClick={() => onClickBtnCopyWorkTime("workTimeTue")}
              className="btn-copy-work-time"
              disabled={!workDays.workDayTue}
            >
              <CopyOutlined />
              Продублювати на всі дні
            </Button>
          </Space>
          {/*** Wed ***/}
          <Space direction="horizontal" className="space-to-row">
            <Form.Item>
              <Checkbox
                className="item-work-days__checkbox"
                checked={workDays.workDayWed}
                onChange={() => onChangeCheckbox("workDayWed")}
              >
                Середа
              </Checkbox>
            </Form.Item>
            <Form.Item className="item-work-days" label="Робочі години">
              <TimePicker.RangePicker
                value={workTimes.workTimeWed}
                disabled={!workDays.workDayWed}
                format="HH:mm"
                minuteStep="5"
                placeholder={["Початок", "Кінець"]}
                onChange={(e) => onChangeTimePicker(e, "workTimeWed")}
              />
            </Form.Item>
            <Button
              onClick={() => onClickBtnCopyWorkTime("workTimeWed")}
              className="btn-copy-work-time"
              disabled={!workDays.workDayWed}
            >
              <CopyOutlined />
              Продублювати на всі дні
            </Button>
          </Space>

          {/*** Thu ***/}
          <Space direction="horizontal" className="space-to-row">
            <Form.Item>
              <Checkbox
                className="item-work-days__checkbox"
                checked={workDays.workDayThu}
                onChange={() => onChangeCheckbox("workDayThu")}
              >
                Четвер
              </Checkbox>
            </Form.Item>
            <Form.Item className="item-work-days" label="Робочі години">
              <TimePicker.RangePicker
                value={workTimes.workTimeThu}
                disabled={!workDays.workDayThu}
                format="HH:mm"
                minuteStep="5"
                placeholder={["Початок", "Кінець"]}
                onChange={(e) => onChangeTimePicker(e, "workTimeThu")}
              />
            </Form.Item>
            <Button
              onClick={() => onClickBtnCopyWorkTime("workTimeThu")}
              className="btn-copy-work-time"
              disabled={!workDays.workDayThu}
            >
              <CopyOutlined />
              Продублювати на всі дні
            </Button>
          </Space>
          {/*** Fri ***/}
          <Space direction="horizontal" className="space-to-row">
            <Form.Item>
              <Checkbox
                className="item-work-days__checkbox"
                checked={workDays.workDayFri}
                onChange={() => onChangeCheckbox("workDayFri")}
              >
                П'ятниця
              </Checkbox>
            </Form.Item>
            <Form.Item className="item-work-days" label="Робочі години">
              <TimePicker.RangePicker
                value={workTimes.workTimeFri}
                disabled={!workDays.workDayFri}
                format="HH:mm"
                minuteStep="5"
                placeholder={["Початок", "Кінець"]}
                onChange={(e) => onChangeTimePicker(e, "workTimeFri")}
              />
            </Form.Item>
            <Button
              onClick={() => onClickBtnCopyWorkTime("workTimeFri")}
              className="btn-copy-work-time"
              disabled={!workDays.workDayFri}
            >
              <CopyOutlined />
              Продублювати на всі дні
            </Button>
          </Space>
          {/*** Sat ***/}
          <Space direction="horizontal" className="space-to-row">
            <Form.Item>
              <Checkbox
                className="item-work-days__checkbox"
                checked={workDays.workDaySat}
                onChange={() => onChangeCheckbox("workDaySat")}
              >
                Субота
              </Checkbox>
            </Form.Item>
            <Form.Item className="item-work-days" label="Робочі години">
              <TimePicker.RangePicker
                value={workTimes.workTimeSat}
                disabled={!workDays.workDaySat}
                format="HH:mm"
                minuteStep="5"
                placeholder={["Початок", "Кінець"]}
                onChange={(e) => onChangeTimePicker(e, "workTimeSat")}
              />
            </Form.Item>
            <Button
              onClick={() => onClickBtnCopyWorkTime("workTimeSat")}
              className="btn-copy-work-time"
              disabled={!workDays.workDaySat}
            >
              <CopyOutlined />
              Продублювати на всі дні
            </Button>
          </Space>
          {/*** Sun ***/}
          <Space direction="horizontal" className="space-to-row">
            <Form.Item>
              <Checkbox
                className="item-work-days__checkbox"
                checked={workDays.workDaySun}
                onChange={() => onChangeCheckbox("workDaySun")}
              >
                Неділя
              </Checkbox>
            </Form.Item>
            <Form.Item className="item-work-days" label="Робочі години">
              <TimePicker.RangePicker
                value={workTimes.workTimeSun}
                disabled={!workDays.workDaySun}
                format="HH:mm"
                minuteStep="5"
                placeholder={["Початок", "Кінець"]}
                onChange={(e) => onChangeTimePicker(e, "workTimeSun")}
              />
            </Form.Item>
            <Button
              onClick={() => onClickBtnCopyWorkTime("workTimeSun")}
              className="btn-copy-work-time"
              disabled={!workDays.workDaySun}
            >
              <CopyOutlined />
              Продублювати на всі дні
            </Button>
          </Space>
        </Col>
        <Divider className={"divider-form"} />
        <Form.Item className="general-form-buttons-submit">
          <Button
            htmlType="button"
            style={{ marginRight: "24px" }}
            onClick={() =>
              navigate(prevUrl, { state: { activeTab: "places" } })
            }
          >
            Закрити
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Зберегти
          </Button>
        </Form.Item>
      </Form>

      {/* </Col> */}

      <Anchor
        getContainer={() => document.querySelector(".form-general-info")}
        className="anchor"
        items={[
          {
            key: "part-1",
            href: "#part-1",
            title: "Загальна інформація",
          },
          {
            key: "part-2",
            href: "#part-2",
            title: "Контактна інформація",
          },
          {
            key: "part-4",
            href: "#part-4",
            title: "Розташування",
          },
          {
            key: "part-5",
            href: "#part-5",
            title: "Розклад роботи",
          },
        ]}
      />
    </div>
  );
};
