import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFirestore,
  collection,
  query,
  where,
  addDoc,
  Timestamp,
  doc,
  updateDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";

import "../../../firebase";
import { DEFAULT_STATUSES, DEFAULT_SUB_COLLECTIONS } from "./enums";
import { message } from "redux/utils/GlobalComponent";

const db = getFirestore();

export const generateBusinessCollection = createAsyncThunk(
  "business/generateBusinessCollection",
  async (placeId, { rejectWithValue }) => {
    try {
      const businessCollectionQuery = query(
        collection(db, "business_collection"),
        where("placeId", "==", placeId)
      );
      const businessCollectionSnapshot = await getDocs(businessCollectionQuery);

      if (!businessCollectionSnapshot.empty) {
        message.error("Для цього місця вже створено бізнес модуль");
        return rejectWithValue("Business module already exist");
      }
      const placeDoc = doc(db, "places", placeId);
      const newCollectionData = {
        placeId,
        placeRef: placeDoc,
        orderStatuses: DEFAULT_STATUSES,
      };
      const docRef = await addDoc(
        collection(db, "business_collection"),
        newCollectionData
      );

      if (docRef) {
        for (const collectionName of DEFAULT_SUB_COLLECTIONS) {
          const subCollectionRef = collection(docRef, collectionName);
          await setDoc(doc(subCollectionRef, "placeholderId"), {
            id: "placeholderId",
          });
        }
        await updateDoc(doc(db, "business_collection", docRef.id), {
          id: docRef.id,
          createdAt: Timestamp.fromDate(new Date()),
        });
      }

      return docRef.id;
    } catch (error) {
      message.error("Під час створення бізнес модулю виникла помилка");
      return rejectWithValue(error.message ?? "Created with error");
    }
  }
);
