import { createSlice } from "@reduxjs/toolkit";
import {handleHowToUseItem, handleHowToUseList, handleSuggestion, handleSuggestions} from "./how-to-use/helpers";

const state = {
  states: [],
  currentState: {
    name: null,
    id: null,
  },
  districts: [],
  currentDistrict: {
    name: null,
    id: null,
  },
  communities: [],
  currentCommunity: {
    id: null,
    name: null,
    isAvailable: null,
    uuid: null,
  },
  towns: [],
  places: [],
  activePermission: null,
  admins: null,
  usersNoAdmins: null,
  activePlace: null,
  adminsPlace: null,
  usersNoAdminsPlace: null,
  howToUseList: null,
  howToUseItem: null,
  suggestions: null,
  suggestion: null
};

export const communitySlice = createSlice({
  name: "community",
  initialState: state,
  reducers: {
    updateStates: (_, { payload }) => ({
      ...state,
      states: payload,
    }),
    updateСurrentState: (state, { payload }) => ({
      ...state,
      currentState: payload,
    }),
    updateDistricts: (state, { payload }) => ({
      ...state,
      districts: payload,
      activePermission: null,
      admins: null,
      usersNoAdmins: null,
    }),
    updateСurrentDistrict: (state, { payload }) => ({
      ...state,
      currentDistrict: payload,
    }),
    updateCommunities: (state, { payload }) => ({
      ...state,
      communities: payload,
      activePermission: null,
      admins: null,
      usersNoAdmins: null,
    }),
    updateСurrentCommunity: (state, { payload }) => ({
      ...state,
      currentCommunity: payload,
    }),
    updateTowns: (state, { payload }) => ({
      ...state,
      towns: payload,
    }),
    updateActivePermission: (state, { payload }) => ({
      ...state,
      activePermission: payload,
    }),
    updateAdmins: (state, { payload }) => ({
      ...state,
      admins: payload,
    }),
    updateUsersNoAdmins: (state, { payload }) => ({
      ...state,
      usersNoAdmins: payload,
    }),
    updatePlaces: (state, { payload }) => ({
      ...state,
      places: payload,
    }),
    addActivePlace: (state, { payload }) => ({
      ...state,
      activePlace: payload,
    }),
    updateAdminsPlace: (state, { payload }) => ({
      ...state,
      adminsPlace: payload,
    }),
    updateUsersNoAdminsPlace: (state, { payload }) => ({
      ...state,
      usersNoAdminsPlace: payload,
    }),
    setHowToUseListOperation: handleHowToUseList,
    setHowToUseItemOperation: handleHowToUseItem,
    setSuggestionsOperation: handleSuggestions,
    setSuggestionOperation: handleSuggestion,
  },
});


export const {
  setHowToUseListOperation,
  setHowToUseItemOperation,
  setSuggestionsOperation,
  setSuggestionOperation
} = communitySlice.actions;
