import { useState, useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";

const containerStyle = {
  width: "100%",
  height: "360px",
};

// const LocationData = () => {
//   const { activePlace } = useSelector((state) => state.cityCouncil);

//   return {
//     lat: activePlace?.geometry?.location?.lat,
//     lon: activePlace?.geometry?.location?.lng,
//   };
// };

// export const LocationComponent = () => {
//   const { isLoaded } = useLoadScript({
//     googleMapsApiKey: "AIzaSyAoj8YQGoTRsVTjeW1SiQ902-zODg-MUmw",
//   });

//   if (!isLoaded) return <div>Loading...</div>;
//   return <Map />;
// };

// function Map() {
//   const data = LocationData();

//   const [latit, setLat] = useState(data.lat);
//   const [longit, setLongit] = useState(data.lon);

//   const center = useMemo(
//     () => ({ lat: Number(data.lat), lng: Number(data.lon) }),
//     [data.lat, data.lon]
//   );

//   const onClickMarker = (loc) => {
//     const lat = loc.latLng.lat();
//     const lng = loc.latLng.lng();

//     setLat(lat);
//     setLongit(lng);
//   };

//   return (
//     <GoogleMap
//       zoom={14}
//       center={center}
//       mapContainerStyle={containerStyle}
//       onClick={onClickMarker}
//     >
//       <Marker position={{ lat: latit, lng: longit }} />
//     </GoogleMap>
//   );
// }

// const LocationData = () => {
// const { activePlace } = useSelector((state) => state.cityCouncil);

//   return {
//     lat: activePlace?.geometry?.location?.lat,
//     lon: activePlace?.geometry?.location?.lng,
//   };
// };

export const LocationComponent = ({ onChangeData }) => {
  const { activePlace } = useSelector((state) => state.community);

  const latitudeFromDB = activePlace?.geometry?.location?.lat;
  const longitudeFromDB = activePlace?.geometry?.location?.lng;

  const [latitude, setLatitude] = useState(latitudeFromDB);
  const [longitude, setLongitude] = useState(longitudeFromDB);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAoj8YQGoTRsVTjeW1SiQ902-zODg-MUmw",
  });

  const center = useMemo(
    () => ({
      lat: Number(latitudeFromDB),
      lng: Number(longitudeFromDB),
    }),
    [latitudeFromDB, longitudeFromDB]
  );

  const onClickMarker = (loc) => {
    const lat = loc.latLng.lat();
    const lng = loc.latLng.lng();

    setLatitude(lat);
    setLongitude(lng);

    onChangeData({
      lat,
      lng,
    });
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      zoom={14}
      center={center}
      mapContainerStyle={containerStyle}
      onClick={onClickMarker}
    >
      <Marker position={{ lat: latitude, lng: longitude }} />
    </GoogleMap>
  );
};
