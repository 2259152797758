export const DEFAULT_STATUSES = [
	{
		color: '#ffffff',
		translate: 'Нові',
		type: 'NEW',
	},
];

export const DEFAULT_SUB_COLLECTIONS = ['orders', 'goods', 'leads'];

