import emailjs from "@emailjs/browser";
import {
  getFirestore,
  collection,
  query,
  where,
  addDoc,
  onSnapshot,
  doc,
  getDocs,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { storage } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { communitySlice } from "./communityReducer";
import { createPass } from "utils/createPassword";
const {
  updateStates,
  updateСurrentState,
  updateDistricts,
  updateСurrentDistrict,
  updateCommunities,
  updateСurrentCommunity,
  updateTowns,
  updateActivePermission,
  updateAdmins,
  updateUsersNoAdmins,
  updatePlaces,
  addActivePlace,
  updateAdminsPlace,
  updateUsersNoAdminsPlace,
} = communitySlice.actions;

const db = getFirestore();

export const getStates = () => async (dispatch, getState) => {
  try {
    const q = query(collection(db, "states"));

    onSnapshot(q, (querySnapshot) => {
      const states = [];
      querySnapshot.forEach((doc) => {
        states.push(doc.data());
      });

      // const querySnapshot = await getDocs(q);
      // const states = querySnapshot.docs.map((doc) => doc.data());

      const sortStates = states.sort((a, b) =>
        a.name.uk.localeCompare(b.name.uk)
      );

      dispatch(updateStates(sortStates));
    });
  } catch (error) {
    console.log("error", error);
    console.log("error.code", error.code);
    console.log("error.message", error.message);
  }
};

export const getDistricts = (stateId) => async (dispatch, getState) => {
  const { community } = getState();
  const states = community.states;
  const state = states.find((item) => item.id === Number(stateId));

  try {
    const q = query(
      collection(db, "districts"),
      where("parentId", "==", stateId)
    );
    const querySnapshot = await getDocs(q);
    const districts = querySnapshot.docs.map((doc) => doc.data());

    const sortDistricts = districts.sort((a, b) =>
      a.name.uk.localeCompare(b.name.uk)
    );

    dispatch(updateDistricts(sortDistricts));
    dispatch(
      updateСurrentState({
        name: state.publicName.uk,
        id: state.id,
      })
    );
  } catch (error) {
    console.log("error", error);
    console.log("error.code", error.code);
    console.log("error.message", error.message);
  }
};

export const getCommunities = (districtId) => async (dispatch, getState) => {
  const { community } = getState();
  const districts = community.districts;
  const district = districts.find((item) => item.id === Number(districtId));

  try {
    const q = query(
      collection(db, "communities"),
      where("parentId", "==", districtId)
    );
    const querySnapshot = await getDocs(q);
    const communities = querySnapshot.docs.map((doc) => doc.data());

    const sortCommunities = communities.sort((a, b) =>
      a.name.uk.localeCompare(b.name.uk)
    );

    const refreshCom = sortCommunities.map((com) => {
      return {
        id: com.id,
        key: com.id,
        name: com.publicName.uk,
        isAvailable: com.available,
        parentId: com.parentId,
        uuid: com.uuid,
      };
    });

    dispatch(updateCommunities(refreshCom));
    dispatch(
      updateСurrentDistrict({
        name: district.publicName.uk,
        id: district.id,
      })
    );
  } catch (error) {
    console.log("error", error);
    console.log("error.code", error.code);
    console.log("error.message", error.message);
  }
};

export const getTowns = (communitytId) => async (dispatch, getState) => {
  const { community } = getState();
  const communities = community.communities;
  const currCommunity = communities.find(
    (item) => item.id === Number(communitytId)
  );

  try {
    const q = query(
      collection(db, "townes"),
      where("parentId", "==", communitytId)
    );
    const querySnapshot = await getDocs(q);
    const towns = querySnapshot.docs.map((doc) => doc.data());

    const sortTowns = towns.sort((a, b) => a.name.uk.localeCompare(b.name.uk));

    dispatch(updateTowns(sortTowns));
    dispatch(
      updateСurrentCommunity({
        name: currCommunity.name,
        id: currCommunity.id,
        isAvailable: currCommunity.isAvailable,
        uuid: currCommunity.uuid,
      })
    );
  } catch (error) {
    console.log("error", error);
    console.log("error.code", error.code);
    console.log("error.message", error.message);
  }
};
/* 
const q = query(
  collection(db, "services"),
  where("categoryId", "==", categoryId),
  where("deleted", "==", false)
);

onSnapshot(q, async (querySnapshot) => {
  let services = [];
  let key = 0;
  querySnapshot.forEach(async (doc) => {
    key += 1;
    services.push({
      ...doc.data(),
      key,
    });
  });
  const servicesSort = services.sort((a, b) => a.index - b.index);

  dispatch(addServices(servicesSort));
}); */
export const getPlaces = (communityId) => async (dispatch, getState) => {
  try {
    const q = query(
      collection(db, "places"),
      where("communityId", "==", communityId)
    );
    onSnapshot(q, async (querySnapshot) => {
      let places = [];
      let key = 0;
      querySnapshot.forEach(async (doc) => {
        key += 1;
        places.push({
          ...doc.data(),
          key,
        });
      });

      const sortPlaces = places.sort((a, b) => a.name.localeCompare(b.name));

      dispatch(updatePlaces(sortPlaces));
    });
  } catch (error) {
    console.log("error", error);
    console.log("error.code", error.code);
    console.log("error.message", error.message);
  }
};

export const updateStatusCommunity =
  (communitytUuid, status) => async (dispatch, getState) => {
    try {
      const updDocRef = doc(db, "communities", communitytUuid);

      await updateDoc(updDocRef, {
        available: status,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error.code", error.code);
      console.log("error.message", error.message);
    }
  };

export const getAdministrators =
  (communityId, isPlace) => async (dispatch, getState) => {
    try {
      const q = query(
        collection(db, "permission"),
        where("communityId", "==", communityId)
      );

      onSnapshot(q, (querySnapshot) => {
        let admins = [];
        querySnapshot.forEach((doc) => {
          admins.push(doc.data());
        });

        if (admins.length > 0) {
          dispatch(updateActivePermission(admins[0]));

          const adminsIdList = admins[0].users;

          const q2 = query(collection(db, "users"));

          onSnapshot(q2, (querySnapshot) => {
            let allUsers = [];
            querySnapshot.forEach((doc) => {
              allUsers.push(doc.data());
            });

            let id = 0;
            const allUsersWidthKeys = allUsers.map((user) => {
              id = id + 1;
              return {
                ...user,
                key: id,
              };
            });

            const activeAdmins = allUsersWidthKeys.filter((user) =>
              adminsIdList.includes(user.uid)
            );
            const freeUsers = allUsersWidthKeys.filter(
              (user) => !adminsIdList.includes(user.uid)
            );

            dispatch(updateUsersNoAdmins(freeUsers));
            dispatch(updateAdmins(activeAdmins));
          });
        } else {
          const q = query(collection(db, "users"));

          onSnapshot(q, (querySnapshot) => {
            let allUsers = [];
            querySnapshot.forEach((doc) => {
              allUsers.push(doc.data());
            });

            let id = 0;
            const allUsersWidthKeys = allUsers.map((user) => {
              id = id + 1;
              return {
                ...user,
                key: id,
              };
            });

            dispatch(updateUsersNoAdmins(allUsersWidthKeys));
            dispatch(updateAdmins([]));
          });
        }
      });
    } catch (error) {
      console.log("error", error);
      console.log("error.code", error.code);
      console.log("error.message", error.message);
    }
  };

export const addNewAdmin =
  (currentCommunity, emailNewAdmin, messageSucces, messageError) =>
  async (dispatch, getState) => {
    try {
      const { community } = getState();
      const usersNoAdmins = community.usersNoAdmins;
      const activePermission = community.activePermission;
      if (!activePermission) {
        let uidNewAdmin;

        const isUser = usersNoAdmins.filter(
          (user) => emailNewAdmin === user.email
        );

        if (isUser.length > 0) {
          uidNewAdmin = isUser[0].uid;

          // SEND LOGIN
          console.log("SEND LOGIN - 1");
          dispatch(
            sendEmail({
              email: emailNewAdmin,
              name: currentCommunity.name,
              isNewAdmin: false,
            })
          );
        } else {
          // const docRef = await addDoc(collection(db, "users"), {
          //   email: emailNewAdmin,
          //   communityId: currentCommunity.id,
          // });

          // if (docRef) {
          // await updateDoc(doc(db, "users", docRef.id), {
          //   id: docRef.id,
          // });

          // uidNewAdmin = docRef.id;

          // * * *  Create NEW user with Email & Password  * * * //
          const pass = createPass();
          const auth = getAuth();
          const newUser = await createUserWithEmailAndPassword(
            auth,
            emailNewAdmin,
            pass
          );

          if (newUser) {
            console.log("newUser:", newUser, newUser.user.uid, "pass:", pass);

            const docRef = await addDoc(collection(db, "users"), {
              email: emailNewAdmin,
              uid: newUser.user.uid,
            });

            if (docRef) {
              await updateDoc(doc(db, "users", docRef.id), {
                id: docRef.id,
              });
            }

            uidNewAdmin = newUser.user.uid;

            // SEND REGISTER
            console.log("SEND REGISTER - 1");
            dispatch(
              sendEmail({
                email: emailNewAdmin,
                pass,
                name: currentCommunity.name,
                isNewAdmin: true,
              })
            );
          }
        }

        const docRef = await addDoc(collection(db, "permission"), {
          role: "admin",
          communityId: currentCommunity.id,
          users: [uidNewAdmin],
        });

        if (docRef) {
          await updateDoc(doc(db, "permission", docRef.id), {
            id: docRef.id,
          });
        }
      } else {
        const { users, id } = activePermission;

        const isUser = usersNoAdmins.filter(
          (user) => emailNewAdmin === user.email
        );

        let uidNewAdmin;

        if (isUser.length > 0) {
          uidNewAdmin = isUser[0].uid;

          // SEND LOGIN
          console.log("SEND LOGIN - 2");
          dispatch(
            sendEmail({
              email: emailNewAdmin,
              name: currentCommunity.name,
              isNewAdmin: false,
            })
          );
        } else {
          // const docRef = await addDoc(collection(db, "users"), {
          //   email: emailNewAdmin,
          //   communityId: currentCommunity.id,
          // });

          // if (docRef) {
          // if (2 > 1) {
          // await updateDoc(doc(db, "users", docRef.id), {
          //   id: docRef.id,
          // });

          // idNewAdmin = docRef.id;

          // * * *  Create NEW user with Email & Password  * * * //
          const pass = createPass();
          const auth = getAuth();
          const newUser = await createUserWithEmailAndPassword(
            auth,
            emailNewAdmin,
            pass
          );
          console.log("newUser:", newUser);

          if (newUser) {
            console.log("newUser:", newUser.user.uid, "pass:", pass);

            const docRef = await addDoc(collection(db, "users"), {
              email: emailNewAdmin,
              uid: newUser.user.uid,
            });

            if (docRef) {
              await updateDoc(doc(db, "users", docRef.id), {
                id: docRef.id,
              });
            }

            uidNewAdmin = newUser.user.uid;

            // SEND REGISTER
            console.log("SEND REGISTER - 2");
            dispatch(
              sendEmail({
                email: emailNewAdmin,
                pass,
                name: currentCommunity.name,
                isNewAdmin: true,
              })
            );
          }
        }

        const updUsers = [...users, uidNewAdmin];

        await updateDoc(doc(db, "permission", id), {
          users: updUsers,
        });
      }

      messageSucces();
    } catch (error) {
      messageError();
      console.log("error", error);
      console.log("error.code", error.code);
      console.log("error.message", error.message);
    }
  };

export const getAdministratorsActivePlace =
  (placeId) => async (dispatch, getState) => {
    try {
      const q = query(
        collection(db, "permission"),
        where("placeId", "==", placeId)
      );

      onSnapshot(q, (querySnapshot) => {
        let admins = [];
        querySnapshot.forEach((doc) => {
          admins.push(doc.data());
        });

        if (admins.length > 0) {
          dispatch(updateActivePermission(admins[0]));

          const adminsIdList = admins[0].users;

          const q2 = query(collection(db, "users"));

          onSnapshot(q2, (querySnapshot) => {
            let allUsers = [];
            querySnapshot.forEach((doc) => {
              allUsers.push(doc.data());
            });

            let id = 0;
            const allUsersWidthKeys = allUsers.map((user) => {
              id = id + 1;
              return {
                ...user,
                key: id,
              };
            });

            const activeAdmins = allUsersWidthKeys.filter((user) =>
              adminsIdList.includes(user.uid)
            );
            const freeUsers = allUsersWidthKeys.filter(
              (user) => !adminsIdList.includes(user.uid)
            );

            dispatch(updateUsersNoAdminsPlace(freeUsers));
            dispatch(updateAdminsPlace(activeAdmins));
          });
        } else {
          dispatch(updateActivePermission(null));
          const q = query(collection(db, "users"));

          onSnapshot(q, (querySnapshot) => {
            let allUsers = [];
            querySnapshot.forEach((doc) => {
              allUsers.push(doc.data());
            });

            let id = 0;
            const allUsersWidthKeys = allUsers.map((user) => {
              id = id + 1;
              return {
                ...user,
                key: id,
              };
            });

            dispatch(updateUsersNoAdminsPlace(allUsersWidthKeys));
            dispatch(updateAdminsPlace([]));
          });
        }
      });
    } catch (error) {
      console.log("error", error);
      console.log("error.code", error.code);
      console.log("error.message", error.message);
    }
  };

export const addNewAdminPlace =
  (currentPlace, emailNewAdmin, messageSucces, messageError) =>
  async (dispatch, getState) => {
    try {
      const { community } = getState();
      const usersNoAdminsPlace = community.usersNoAdminsPlace;
      const activePermission = community.activePermission;
      console.log("activePermission:", activePermission);
      if (!activePermission) {
        let uidNewAdmin;

        const isUser = usersNoAdminsPlace.filter(
          (user) => emailNewAdmin === user.email
        );

        if (isUser.length > 0) {
          uidNewAdmin = isUser[0].uid;

          // SEND LOGIN
          console.log("SEND LOGIN - 1");
          dispatch(
            sendEmail({
              email: emailNewAdmin,
              name: currentPlace.name,
              isNewAdmin: false,
            })
          );
        } else {
          // const docRef = await addDoc(collection(db, "users"), {
          //   email: emailNewAdmin,
          //   communityId: currentCommunity.id,
          // });

          // if (docRef) {
          // await updateDoc(doc(db, "users", docRef.id), {
          //   id: docRef.id,
          // });

          // uidNewAdmin = docRef.id;

          // * * *  Create NEW user with Email & Password  * * * //
          const pass = createPass();
          const auth = getAuth();
          const newUser = await createUserWithEmailAndPassword(
            auth,
            emailNewAdmin,
            pass
          );

          if (newUser) {
            console.log("newUser:", newUser.user.uid, "pass:", pass);

            const docRef = await addDoc(collection(db, "users"), {
              email: emailNewAdmin,
              uid: newUser.user.uid,
            });

            if (docRef) {
              await updateDoc(doc(db, "users", docRef.id), {
                id: docRef.id,
              });
            }

            uidNewAdmin = newUser.user.uid;

            // SEND REGISTER
            console.log("SEND REGISTER - 1");
            dispatch(
              sendEmail({
                email: emailNewAdmin,
                pass,
                name: currentPlace.name,
                isNewAdmin: true,
              })
            );
          }
        }

        const docRef = await addDoc(collection(db, "permission"), {
          role: "admin",
          placeId: currentPlace.placeId,
          users: [uidNewAdmin],
        });

        if (docRef) {
          await updateDoc(doc(db, "permission", docRef.id), {
            id: docRef.id,
          });
        }
      } else {
        const { users, id } = activePermission;

        const isUser = usersNoAdminsPlace.filter(
          (user) => emailNewAdmin === user.email
        );

        let uidNewAdmin;

        if (isUser.length > 0) {
          uidNewAdmin = isUser[0].uid;

          // SEND LOGIN
          console.log("SEND LOGIN - 2");
          dispatch(
            sendEmail({
              email: emailNewAdmin,
              name: currentPlace.name,
              isNewAdmin: false,
            })
          );
        } else {
          // const docRef = await addDoc(collection(db, "users"), {
          //   email: emailNewAdmin,
          //   communityId: currentCommunity.id,
          // });

          // if (docRef) {
          // if (2 > 1) {
          // await updateDoc(doc(db, "users", docRef.id), {
          //   id: docRef.id,
          // });

          // idNewAdmin = docRef.id;

          // * * *  Create NEW user with Email & Password  * * * //
          const pass = createPass();
          const auth = getAuth();
          const newUser = await createUserWithEmailAndPassword(
            auth,
            emailNewAdmin,
            pass
          );

          if (newUser) {
            console.log("newUser:", newUser.user.uid, "pass:", pass);

            const docRef = await addDoc(collection(db, "users"), {
              email: emailNewAdmin,
              uid: newUser.user.uid,
            });

            if (docRef) {
              await updateDoc(doc(db, "users", docRef.id), {
                id: docRef.id,
              });
            }

            uidNewAdmin = newUser.user.uid;

            // SEND REGISTER
            console.log("SEND REGISTER - 2");
            dispatch(
              sendEmail({
                email: emailNewAdmin,
                pass,
                name: currentPlace.name,
                isNewAdmin: true,
              })
            );
          }
        }

        const updUsers = [...users, uidNewAdmin];

        await updateDoc(doc(db, "permission", id), {
          users: updUsers,
        });
      }

      messageSucces("new");
    } catch (error) {
      messageError("new");
      console.log("error", error);
      console.log("error.code", error.code);
      console.log("error.message", error.message);
    }
  };

export const deleteAdmin =
  (idAdmin, messageSucces, messageError) => async (dispatch, getState) => {
    try {
      const { community } = getState();
      const activePermission = community.activePermission;
      const { users, id } = activePermission;

      const updUsers = users.filter((user) => user !== idAdmin);

      await updateDoc(doc(db, "permission", id), {
        users: updUsers,
      });

      messageSucces();
    } catch (error) {
      messageError();
      console.log("error", error);
      console.log("error.code", error.code);
      console.log("error.message", error.message);
    }
  };

export const sendEmail = (templateParams) => async (dispatch, getState) => {
  const template = templateParams.isNewAdmin
    ? "template_q5tc5ts"
    : "template_3tilknd";

  console.log("sendEmail  isNewAdmin:", templateParams.isNewAdmin);
  try {
    emailjs.send(
      "service_a61wlnu",
      template,
      templateParams,
      "OFb28TmkEHW_spymd"
    );
  } catch (error) {
    console.log("error", error);
    console.log("error.code", error.code);
    console.log("error.message", error.message);
  }
};

export const getActivePlace = (place) => async (dispatch, getState) => {
  // const q = query(collection(db, "places"), where("placeId", "==", placeId));

  // const querySnapshot = await getDocs(q);
  // let activePlace;
  // querySnapshot.forEach((doc) => {
  //   activePlace = doc.data();
  // });

  dispatch(addActivePlace(place));
};

export const saveEditCityCouncilSettings =
  (data, images, messageSucces, messageError) => async (dispatch, getState) => {
    try {
      let savedImages = [];

      for (let v = 0; v < images.length; v++) {
        if (images[v].originFileObj) {
          const name = `${uuidv4()}-${images[v].name}`;

          const imageRef = ref(storage, `/places_photo/${data.id}/${name}`);

          await uploadBytes(imageRef, images[v].originFileObj);

          const url = await getDownloadURL(
            ref(storage, `/places_photo/${data.id}/${name}`)
          );

          savedImages.push(url);
        } else {
          savedImages.push(images[v].url);
        }

        if (v === images.length - 1) {
          await updateDoc(doc(db, "places", data.id), {
            ...data,
            images: savedImages,
          });
        }
      }
      messageSucces();
    } catch (error) {
      messageError();
      console.log("error", error);
      console.log("error.code", error.code);
      console.log("error.message", error.message);
    }
  };
