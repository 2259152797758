import dayjs from "dayjs";

export const emailList = (email, list) => {
  if (!email && list.length === 0) return null;

  if (email && email.length > 0 && list.length === 0) {
    const emails = [];
    emails.push(email);
    return emails;
  }

  if (
    email &&
    email.length > 0 &&
    list.length > 0 &&
    list[0].email.length > 0
  ) {
    const emails = [];
    emails.push(email);

    list.map((item) => emails.push(item.email));

    return emails;
  }

  if (!email && list.length > 0 && list[0].email.length > 0) {
    const emails = [];

    list.map((item) => emails.push(item.email));

    return emails;
  }

  // all other variants
  return null;
};

export const socialNetworksList = (facebook, instagram) => {
  if (!facebook && !instagram) return null;

  if (facebook && instagram) {
    return {
      facebook,
      instagram,
    };
  }
  if (facebook && !instagram) {
    return {
      facebook,
    };
  }
  if (!facebook && instagram) {
    return {
      instagram,
    };
  }
};

export const messengersList = (telegram, viber) => {
  if (!telegram && !viber) return null;

  if (telegram && viber) {
    return {
      telegram,
      viber,
    };
  }
  if (telegram && !viber) {
    return {
      telegram,
    };
  }
  if (!telegram && viber) {
    return {
      viber,
    };
  }
};

export const phonesList = (name, phone, list) => {
  if (!name && !phone && list.length === 0) return null;

  if (name && name.length > 0 && !phone && list.length === 0) {
    return [{ name, phone: null }];
  }

  if (!name && phone && phone.length > 0 && list.length === 0) {
    return [{ name: null, phone }];
  }

  if (
    name &&
    name.length > 0 &&
    phone &&
    phone.length > 0 &&
    list.length === 0
  ) {
    return [{ name, phone }];
  }

  if (list.length > 0 && name && name.length > 0 && phone && phone.length > 0) {
    const phones = [{ name, phone }];

    list.map((item) => phones.push(item));

    return phones;
  }

  if (list.length > 0 && !name && !phone) {
    const phones = [];

    list.map((item) => phones.push(item));

    return phones;
  }

  if (list.length > 0 && name && name.length > 0 && !phone) {
    const phones = [{ name, phone: null }];

    list.map((item) => phones.push(item));

    return phones;
  }

  if (list.length > 0 && !name && phone && phone.length > 0) {
    const phones = [{ name: null, phone }];

    list.map((item) => phones.push(item));

    return phones;
  }

  return null;
};

export const currentWorkDays = (data) => {
  if (!data) {
    return {
      workDayMon: false,
      workDayTue: false,
      workDayWed: false,
      workDayThu: false,
      workDayFri: false,
      workDaySat: false,
      workDaySun: false,
    };
  }

  const workDays = {
    workDayMon: data[0].includes("Зачинено") ? false : true,
    workDayTue: data[1].includes("Зачинено") ? false : true,
    workDayWed: data[2].includes("Зачинено") ? false : true,
    workDayThu: data[3].includes("Зачинено") ? false : true,
    workDayFri: data[4].includes("Зачинено") ? false : true,
    workDaySat: data[5].includes("Зачинено") ? false : true,
    workDaySun: data[6].includes("Зачинено") ? false : true,
  };

  return workDays;
};
export const currentWorkTimes = (data) => {
  const response = {
    workTimeMon: null,
    workTimeTue: null,
    workTimeWed: null,
    workTimeThu: null,
    workTimeFri: null,
    workTimeSat: null,
    workTimeSun: null,
  };

  if (!data) {
    return response;
  }

  const keys = Object.keys(response);

  for (let i = 0; i < keys.length; i++) {
    response[keys[i]] = data[i].includes("Зачинено")
      ? null
      : data[i].includes("24 години")
      ? [getTime("00:00"), getTime("23:59")]
      : [getTime(data[i].slice(-11, -6)), getTime(data[i].slice(-5))];
  }

  function getTime(time) {
    return dayjs(time, "HH:mm");
  }

  return response;
};

export const workTimeValidate = (workDays, workTimes) => {
  const data = {
    monday: {
      isWork: workDays.workDayMon,
      isTime: workTimes.workTimeMon === null ? false : true,
    },
    tuesday: {
      isWork: workDays.workDayTue,
      isTime: workTimes.workTimeTue === null ? false : true,
    },
    wednesday: {
      isWork: workDays.workDayWed,
      isTime: workTimes.workTimeWed === null ? false : true,
    },
    thursday: {
      isWork: workDays.workDayThu,
      isTime: workTimes.workTimeThu === null ? false : true,
    },
    friday: {
      isWork: workDays.workDayFri,
      isTime: workTimes.workTimeFri === null ? false : true,
    },
    saturday: {
      isWork: workDays.workDaySat,
      isTime: workTimes.workTimeSat === null ? false : true,
    },
    sunday: {
      isWork: workDays.workDaySun,
      isTime: workTimes.workTimeSun === null ? false : true,
    },
  };

  const keys = Object.keys(data);

  for (const day of keys) {
    if (data[day].isWork) {
      if (!data[day].isTime) return false;
    }
  }
  return true;
};

export const workTimeData = (workDays, workTimes) => {
  const formatTime = (timeObj) => {
    let hours;
    hours = timeObj.$H.toString();

    if (hours.length < 2) {
      hours = `0${timeObj.$H.toString()}`;
    }

    let minutes;
    minutes = timeObj.$m.toString();

    if (minutes.length < 2) {
      minutes = `0${timeObj.$m.toString()}`;
    }

    return `${hours}:${minutes}`;
  };

  const data = [
    workDays.workDayMon
      ? `понеділок: ${formatTime(workTimes.workTimeMon[0])}-${formatTime(
          workTimes.workTimeMon[1]
        )}`
      : "понеділок: Зачинено",

    workDays.workDayTue
      ? `вівторок: ${formatTime(workTimes.workTimeTue[0])}-${formatTime(
          workTimes.workTimeTue[1]
        )}`
      : "вівторок: Зачинено",

    workDays.workDayWed
      ? `середа: ${formatTime(workTimes.workTimeWed[0])}-${formatTime(
          workTimes.workTimeWed[1]
        )}`
      : "середа: Зачинено",

    workDays.workDayThu
      ? `четвер: ${formatTime(workTimes.workTimeThu[0])}-${formatTime(
          workTimes.workTimeThu[1]
        )}`
      : "четвер: Зачинено",

    workDays.workDayFri
      ? `пʼятниця: ${formatTime(workTimes.workTimeFri[0])}-${formatTime(
          workTimes.workTimeFri[1]
        )}`
      : "пʼятниця: Зачинено",

    workDays.workDaySat
      ? `субота: ${formatTime(workTimes.workTimeSat[0])}-${formatTime(
          workTimes.workTimeSat[1]
        )}`
      : "субота: Зачинено",

    workDays.workDaworkDaySunySat
      ? `неділя: ${formatTime(workTimes.workTimeSun[0])}-${formatTime(
          workTimes.workTimeSun[1]
        )}`
      : "неділя: Зачинено",
  ];

  return data;
};

export const completeAddress = (currentAddress, newDataAddressFromInput) => {
  const formattedAddress = [];

  for (let v = 0; v <= 7; v++) {
    if (v === 0) {
      formattedAddress.push({
        ...currentAddress[v],
        shortName: newDataAddressFromInput.home || null,
      });
      continue;
    }
    if (v === 1) {
      formattedAddress.push({
        ...currentAddress[v],
        shortName: newDataAddressFromInput.street || null,
      });
      continue;
    }
    if (v === 2) {
      formattedAddress.push({
        ...currentAddress[v],
        shortName: newDataAddressFromInput.town || null,
      });
      continue;
    }
    if (v === 7) {
      formattedAddress.push({
        apartment: newDataAddressFromInput.apartment || null,
        entrance: newDataAddressFromInput.entrance || null,
        additionalInfo: newDataAddressFromInput.additionalInfo || null,
      });
      continue;
    }

    formattedAddress.push(currentAddress[v]);
  }

  return formattedAddress;
};
