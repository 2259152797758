import { Breadcrumb, Input, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCommunities } from "redux/community/communityOperations";
import { onScrollUp } from "utils/onScrollUp";
import { SearchOutlined } from "@ant-design/icons";

export const Communities = () => {
  const dispatch = useDispatch();
  const { communities, currentState, currentDistrict } = useSelector(
    (state) => state.community
  );

  const [filteredData, setFilteredData] = useState([]);

  const params = useParams();
  const distcrictId = Number(params.id);

  useEffect(() => {
    if (!communities) return setFilteredData([]);

    setFilteredData(communities);
  }, [communities]);

  useEffect(() => {
    onScrollUp();
    dispatch(getCommunities(distcrictId));
  }, []);

  const onSearchInput = (value, _e, info) => {
    const searchText = value.target.value.toLowerCase().trim();

    const newData = communities.filter((item) => {
      return item.name.toLowerCase().includes(searchText);
    });

    setFilteredData(newData);
  };

  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: "#00000040",
      }}
    />
  );

  const columns = [
    {
      title: "ID громади",
      key: "id",
      width: 130,
      render: (item) => (
        <Link
          style={{ textAlign: "center" }}
          className="community-table__link"
          to={`${item.id}`}
        >
          {item.id}
        </Link>
      ),
      className: "community-table__item",
    },
    {
      title: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            whiteSpace: "nowrap",
          }}
        >
          Територіальні громади{" "}
          <Input
            allowClear
            suffix={suffix}
            size="large"
            placeholder="Пошук по назві"
            onChange={onSearchInput}
            style={{ borderRadius: "0", border: "none" }}
          />
        </span>
      ),
      key: "name",
      render: (item) => (
        <Link className="community-table__link" to={`${item.id}`}>
          {item.name}
        </Link>
      ),
      className: "community-table__item",
    },
    {
      title: "Статус",
      dataIndex: "isAvailable",
      key: "key",
      width: 180,
      sorter: (a, b) => a.isAvailable - b.isAvailable,
      sortDirections: ["descend", "ascend"],
      render: (_, item) => (
        <div
          className={`community-table__status${
            item.isAvailable ? "-active" : ""
          }`}
        >
          <div
            className={`community-table__status-circle${
              item.isAvailable ? "-active" : ""
            }`}
          ></div>
          {item.isAvailable ? "Активна" : "Неактивна"}
        </div>
      ),
    },
  ];

  return (
    <Content className="content">
      <div className="content-header">
        <Breadcrumb
          className="breadcrumb"
          items={[
            {
              title: <Link to="/">Області</Link>,
            },
            {
              title: (
                <Link to={`/state/${currentState.id}`}>
                  {currentState.name}
                </Link>
              ),
            },
            {
              title: (
                <Link to={`/state/${currentState.id}/${currentDistrict.id}`}>
                  {currentDistrict.name}
                </Link>
              ),
            },
            {
              title: "Список територіальних громад",
            },
          ]}
        />
        <div className="content-title">
          <ul className="content-title__list">
            <li className="content-title__item">
              Список територіальних громад
            </li>
            <li className="content-title__item">{currentDistrict.name}</li>
          </ul>
        </div>
      </div>
      <div className="content-conteiner">
        <Table
          pagination={false}
          columns={columns}
          dataSource={filteredData}
          locale={{
            triggerDesc: 'Сортувати: спочатку "Активні"',
            triggerAsc: 'Сортувати: спочатку "Неактивні"',
            cancelSort: "Відмінити сортування",
          }}
          scroll={{
            y: "calc(100vh - 290px)",
          }}
        />
      </div>
    </Content>
  );
};
