import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { AdminHeader } from "components/AdminHeader/AdminHeader";
import { AdminSider } from "components/AdminSider/AdminSider";
import { authSignOutUser } from "redux/auth/authOperations";

export const AdminHomePage = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onClickLogOut = () => {
    dispatch(authSignOutUser());
    navigate("/");
  };

  return (
    <>
      <AdminHeader onClickLogOut={onClickLogOut} />
      <AdminSider>
        <Outlet />
      </AdminSider>
    </>
  );
};
