import { LoadingOutlined } from "@ant-design/icons";

export const Loader = ({ fullScreen }) => {
  return fullScreen ? (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999
      }}
    >
      <LoadingOutlined style={{ fontSize: "90px", color: "#1677ff" }} />
    </div>
  ) : (
    <>
      <LoadingOutlined style={{ fontSize: "20px" }} />
    </>
  );
};
