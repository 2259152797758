import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { authSlice } from "./authReducer";

const {
  updateUserProfile,
  authStateChange,
  authSignOut,
  authError,
  isLoadingChange,
} = authSlice.actions;

export const authSignInUser =
  ({ email, password }) =>
  async (dispatch, getState) => {
    dispatch(isLoadingChange(true));
    try {
      const auth = getAuth();

      await signInWithEmailAndPassword(auth, email, password);

      dispatch(authError({ updError: false }));
      dispatch(authStateChange({ stateChange: true }));
    } catch (error) {
      console.log("error", error);
      console.log("error.code", error.code);
      console.log("error.message", error.message);
      dispatch(authError({ updError: true }));
    } finally {
      dispatch(isLoadingChange(false));
    }
  };

export const authSignOutUser = () => async (dispatch, getState) => {
  const auth = getAuth();
  await signOut(auth);
  await dispatch(authSignOut());
};

export const authStateCahngeUser = () => async (dispatch, getState) => {
  dispatch(isLoadingChange(true));
  const auth = getAuth();

  await onAuthStateChanged(auth, (user) => {
    if (user) {
      const userUpdateProfile = {
        nickName: user.displayName,
        userId: user.uid,
      };

      dispatch(authStateChange({ stateChange: true }));
      dispatch(updateUserProfile(userUpdateProfile));
    }
    dispatch(isLoadingChange(false));
  });
};

export const authSignUpUser =
  ({ email, password, login }) =>
  async (dispatch, getSatte) => {
    try {
      // await auth().createUserWithEmailAndPassword(email, password);
      // const user = await auth().currentUser;
      // await user.updateProfile({
      //   displayName: login,
      // });
      // const { displayName, uid } = await auth().currentUser;
      // const userUpdateProfile = {
      //   login: displayName,
      //   userId: uid,
      // };
      // dispatch(updateUserProfile(userUpdateProfile));
    } catch (error) {
      console.log("error", error);
      console.log("error.message", error.message);
    }
  };
