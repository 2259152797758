import { Breadcrumb, Input, List } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDistricts } from "redux/community/communityOperations";
import { onScrollUp } from "utils/onScrollUp";

export const Districts = () => {
  const dispatch = useDispatch();
  const { districts, currentState } = useSelector((state) => state.community);
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    setFilteredData(districts);
  }, [districts]);

  const onSearchInput = (value, _e, info) => {
    const searchText = value.target.value.toLowerCase().trim();

    const newData = districts.filter((item) => {
      return item.publicName.uk.toLowerCase().includes(searchText);
    });

    setFilteredData(newData);
  };

  const params = useParams();
  const stateId = Number(params.id);

  useEffect(() => {
    onScrollUp();
    dispatch(getDistricts(stateId));
  }, []);

  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: "#00000040",
      }}
    />
  );

  return (
    <Content className="content">
      <div className="content-header">
        <Breadcrumb
          className="breadcrumb"
          items={[
            {
              title: <Link to="/">Області</Link>,
            },
            {
              title: currentState.name,
            },
            {
              title: "Виберіть район",
            },
          ]}
        />
        <div className="content-title">
          <ul className="content-title__list">
            <li className="content-title__item">Виберіть район</li>
            <li className="content-title__item">{currentState.name}</li>
          </ul>
        </div>
      </div>
      <div className="content-conteiner">
        <List
          className="list-header"
          header={
            <Input
              prefix={suffix}
              size="large"
              placeholder="Район"
              onChange={onSearchInput}
              style={{ borderRadius: "0" }}
            />
          }
          itemLayout="horizontal"
          dataSource={filteredData}
          renderItem={(item) => (
            <List.Item className="content-conteiner-list__item">
              <List.Item.Meta
                title={<Link to={`${item.id}`}>{item.publicName.uk}</Link>}
              />
              <div>
                <Link to={`${item.id}`}>
                  <RightOutlined style={{ color: "#00000040" }} />
                </Link>
              </div>
            </List.Item>
          )}
        ></List>
      </div>
    </Content>
  );
};
