import {lazy, useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authStateCahngeUser } from "redux/auth/authOperations";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { AdminHomePage } from "pages/AdminHomePage/AdminHomePage";
import { NotFoundPage } from "pages/NotFoundPage/NotFoundPage";
import { States } from "components/States/States";
import { Districts } from "components/Districts/Districts";
import { Settings } from "components/Settings/Settings";
import { Loader } from "components/Loader/Loader";
import { Communities } from "components/Communities/Communities";
import { Towns } from "components/Towns/Towns";
import { EditPlace } from "components/EditPlace/EditPlace";
import {Loadable} from "./utils/Loadable";

export const App = () => {
  const dispatch = useDispatch();

  const { stateChange, isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(authStateCahngeUser());
  }, [stateChange]);

  return isLoading ? (
    <Loader fullScreen={true} />
  ) : (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="" element={stateChange ? <AdminHomePage /> : <LoginPage />}>
        <Route index element={<States />} />
        <Route path="state/:id" element={<Districts />} />
        <Route path="state/:id/:id" element={<Communities />} />
        <Route path="state/:id/:id/:id" element={<Towns />} />

        <Route path="state/:id/:id/:id/edit-place" element={<EditPlace />} />

        <Route path="settings" element={<Settings />} />
        <Route path="how-to-use" element={<HowToUse />} />
        <Route path="how-to-use/:id/:name" element={<Suggestions />} />
        <Route path="how-to-use/:id/:name/:suggestionId" element={<SuggestionItemCreate />} />
        <Route path="how-to-use/:id/:name/create" element={<SuggestionItemCreate />} />
      </Route>
    </Routes>
  );
};

const HowToUse = Loadable(
    lazy(() => import('components/HowToUse/HowToUse'))
);

const Suggestions = Loadable(
    lazy(() => import('components/HowToUse/suggestions/Suggestions'))
);

const SuggestionItemCreate = Loadable(
    lazy(() => import('components/HowToUse/suggestions/SuggestionItemCreate'))
);
