import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Checkbox, Col, Form, Input } from "antd";
import { MailOutlined, UnlockOutlined } from "@ant-design/icons";

export const LoginForm = ({ handleSubmit }) => {
  const { errorAuthorization } = useSelector((state) => state.auth);
  const [checked, setChecked] = useState(true);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const onChangeChecked = (e) => {
    console.log("checked = ", e.target.checked);
    setChecked(e.target.checked);
  };

  return (
    <Form style={{ marginTop: "24px", textAlign: "left" }}>
      <Form.Item style={{ marginBottom: "16px" }}>
        <Input
          type="email"
          placeholder="Електрона пошта"
          className="login-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <MailOutlined className="login-icon" />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: "16px" }}
        hasFeedback
        validateStatus={errorAuthorization && "error"}
        help={errorAuthorization && "Невірний email або пароль"}
      >
        <Input.Password
          placeholder="Пароль"
          className="login-input"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
        />

        <UnlockOutlined
          className="login-icon"
          style={{ color: errorAuthorization && "#ff4d4f" }}
        />
      </Form.Item>

      <Col
        style={{
          textAlign: "start",
        }}
      >
        <Form.Item>
          <Checkbox checked={checked} onChange={onChangeChecked}>
            Запам'ятати мене
          </Checkbox>
        </Form.Item>
      </Col>

      <Button
        style={{ width: "100%", marginBottom: "70px" }}
        type="primary"
        className="submit__btn"
        htmlType="submit"
        onClick={() => handleSubmit(email, pass)}
      >
        Увійти
      </Button>
    </Form>
  );
};
