import { SearchOutlined } from "@ant-design/icons";
import { Input, List, Switch } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const TownsList = () => {
  const { towns } = useSelector((state) => state.community);
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    setFilteredData(towns);
  }, [towns]);

  const onSearchInput = (value, _e, info) => {
    const searchText = value.target.value.toLowerCase().trim();

    const newData = towns.filter((item) => {
      return item.publicName.uk.toLowerCase().includes(searchText);
    });

    setFilteredData(newData);
  };
  const suffix = (
    <SearchOutlined
      style={{
        fontSize: 16,
        color: "#00000040",
      }}
    />
  );

  return (
    <div className="content-conteiner">
      <List
        className="list-header"
        header={
          <Input
            prefix={suffix}
            size="large"
            placeholder="Населений пункт"
            onChange={onSearchInput}
            style={{ borderRadius: "0" }}
          />
        }
        itemLayout="horizontal"
        dataSource={filteredData}
        renderItem={(item) => (
          <List.Item className="content-conteiner-list__item">
            <span style={{ marginRight: "40px" }}>ID: {item.id} </span>
            <List.Item.Meta title={item.publicName?.uk} />

            <div>
              <Switch />
            </div>
          </List.Item>
        )}
      ></List>
    </div>
  );
};
