import { Suspense } from 'react';
import {Loader} from "../components/Loader/Loader";

// eslint-disable-next-line react/display-name
export const Loadable = (Component) => (props) => {
	return (
		<Suspense fallback={<Loader fullScreen />}>
			<Component {...props} />
		</Suspense>
	);
};
