import { useDispatch } from "react-redux";
import { authSignInUser } from "../../redux/auth/authOperations";
import { Col, Layout, Row, Typography } from "antd";
import { AntDesignIcon } from "components/AntDesignIcon/AntDesignIcon";
import { LoginForm } from "components/LoginForm/LoginForm";

const { Title, Text } = Typography;

export const LoginPage = () => {
  const dispatch = useDispatch();

  const handleLogin = (email, password) => {
    dispatch(
      authSignInUser({
        email,
        password: password.trim(),
      })
    );
  };

  return (
    <>
      <Layout className="layout-style">
        <Row style={{ textAlign: "center" }}>
          <Col style={{ width: "360px" }}>
            <Row style={{ justifyContent: "center" }}>
              <AntDesignIcon width={"46px"} marginRight={"17px"} />
              <Title className="login-title" style={{ margin: "0" }}>
                Моя громада
              </Title>
              <Text className="login-text-title">
                Ласкаво просимо! Введіть свою електронну адресу та пароль нижче,
                щоб увійти
              </Text>
            </Row>
            <LoginForm handleSubmit={handleLogin} />
          </Col>
        </Row>
      </Layout>
    </>
  );
};
