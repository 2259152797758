import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Breadcrumb, Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import { Loader } from "components/Loader/Loader";
import { FormGeneralInfo } from "./EditFormCityCouncilSettings/FormGeneralInfo";
import { AdminsTablePlace } from "components/AdminsTablePlace/AdminsTablePlace";

export const EditPlace = () => {
  const { activePlace, currentState, currentDistrict, currentCommunity } =
    useSelector((state) => state.community);

  const itemsTab = [
    {
      key: "1",
      label: "Основна інформація",
      children: <FormGeneralInfo />,
    },
    {
      key: "2",
      label: "Адміністратори",
      children: <AdminsTablePlace />,
    },
  ];

  return activePlace ? (
    <Content className="content">
      <div className="content-header">
        <Breadcrumb
          className="breadcrumb"
          items={[
            {
              title: <Link to="/">Області</Link>,
            },
            {
              title: (
                <Link to={`/state/${currentState.id}`}>
                  {currentState.name}
                </Link>
              ),
            },
            {
              title: (
                <Link to={`/state/${currentState.id}/${currentDistrict.id}`}>
                  {currentDistrict.name}
                </Link>
              ),
            },
            {
              title: (
                <Link
                  to={`/state/${currentState.id}/${currentDistrict.id}/${currentCommunity.id}`}
                >
                  {currentCommunity.name}
                </Link>
              ),
            },
          ]}
        />
        <div className="content-title">
          <ul className="content-title__list">
            <li className="content-title__item">
              Основна інформація {activePlace?.name}
            </li>
          </ul>
        </div>
      </div>

      <Tabs className="add-form-tabs" defaultActiveKey="1" items={itemsTab} />
    </Content>
  ) : (
    <Loader style={{ margin: "0 auto" }} fullScreen={true} />
  );
};
